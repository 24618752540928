import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import HeroImage from "../Shared/HeroImage";
import RelatedContent from "../Shared/RelatedContent";
import Banner from "./Banner";

const ExpertiseLayout = ({ children, pageContext }: any) => {
    const data = useStaticQuery(
        graphql`
            query TechExpertisesLayout {
                strapiTechExpertiseModule {
                    hero {
                        heading
                        headingBackground {
                            file {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            alternativeText
                        }
                        headingBackgroundMobile {
                            file {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            alternativeText
                        }
                        subHeading
                    }
                    shortBanner {
                        title
                        text
                    }
                    keepReading {
                        heading
                        keepReadingContent {
                            heading
                            slug
                            image {
                                file {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                                alternativeText
                            }
                        }
                    }
                }
            }
        `
    );

    const { strapiTechExpertiseModule } = data;

    return (
        <>
            <HeroImage
                background={strapiTechExpertiseModule.hero.headingBackground}
                backgroundMobile={strapiTechExpertiseModule.hero.headingBackgroundMobile}
                heading={strapiTechExpertiseModule.hero.heading}
                subHeading={strapiTechExpertiseModule.hero.subHeading}
                isSerif={true}
            />
            <Banner shortBanner={strapiTechExpertiseModule.shortBanner} />
            {children}
            <RelatedContent heading={strapiTechExpertiseModule.keepReading.heading} content={strapiTechExpertiseModule.keepReading.keepReadingContent} />
        </>
    );
};
export default ExpertiseLayout;
