import React from "react";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";
import getNavData, { IDataInfo, navigationComponent } from "../navData";
import EventTrackerService from "../../../services/EventTrackerService";
import { navigate } from "gatsby";

interface INavbarProps {
    theme?: string;
    isDesktop?: boolean;
    dropdownOpen?: boolean;
    dropdownContent?: any;
    setDropdownContent?: any;
    setDropdownOpen?: any;
    setNavbarVisible?: any;
}

const Navbar = ({ theme, isDesktop, dropdownOpen, dropdownContent, setDropdownContent, setDropdownOpen, setNavbarVisible }: INavbarProps) => {
    const navData = getNavData(navigationComponent.NAVBAR);

    const handleClick = (current: string) => {
        let currentContent = navData.find((elem: IDataInfo) => elem.name === current);
        currentContent?.sectionChildren && currentContent.sectionChildren.length > 0 ? setDropdown(currentContent) : handleNavigation(current, currentContent);
    };

    const handleNavigation = (current: any, currentContent: any) => {
        EventTrackerService.buttonClick(current, true);
        goToPage(currentContent);
    };

    function goToPage(current: any) {
        if (!current?.slug) return;
        navigate(current.slug);
        setDropdownOpen(false);
    }

    const setDropdown = (current: IDataInfo) => {
        if (current.name === dropdownContent?.name) {
            setDropdownOpen(false);
        }

        if (!dropdownOpen) {
            setDropdownOpen(true);
        }
        setDropdownContent(current);
    };

    const sharedProps = {
        navData,
        theme,
        setNavbarVisible,
        dropdownOpen,
        dropdownContent,
        handleClick,
    };

    return isDesktop ? <NavbarDesktop {...sharedProps} /> : <NavbarMobile {...sharedProps} setDropdownContent={setDropdownContent} />;
};

export default Navbar;
