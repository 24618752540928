import React from "react";
import * as styles from "../Footer.module.scss";
import { Link } from "gatsby";
import getNavData, { IChildrenData, navigationComponent, types } from "../../navData";

interface ISiteMapProps {
    themeDark: boolean;
    currentPath: string;
}

function SiteMap({ themeDark, currentPath }: ISiteMapProps) {
    let navData = getNavData(navigationComponent.SITEMAP);
    return (
        <div className={`${styles.contact} px-4`}>
            <nav className={styles.navContainer}>
                {navData.map((nav: any) => {
                    const section = nav;
                    return (
                        <ul className={styles.mainNav} key={section.name}>
                            {section.sectionChildren ? (
                                <>
                                    <h3>{section.name}</h3>
                                    {section.sectionChildren.map((sectionChild: IChildrenData, i: number) => {
                                        let slug: string;
                                        if (section.type === types.DYNAMIC) {
                                            slug =
                                                section.slug +
                                                (sectionChild.items && sectionChild.items.length > 0 ? sectionChild.items[i].slug : sectionChild.slug);
                                        }
                                        if (section.type === types.MULTIPLE) {
                                            slug =
                                                (sectionChild.slug ? sectionChild.slug : "") +
                                                (sectionChild.items && sectionChild.items.length > 0 ? sectionChild.items[0].slug : sectionChild.slug);
                                        }
                                        if (section.type === types.ONE) {
                                            slug = section.slug + (sectionChild.items && sectionChild.items.length > 0 ? sectionChild.items[0].slug : "");
                                        }

                                        return (
                                            <Link
                                                to={slug}
                                                key={slug}
                                                className={`${themeDark ? styles.isDark : ""} ${slug === currentPath ? styles.isActive : ""} ${
                                                    styles.internalLink
                                                }`}>
                                                <span>{sectionChild.name}</span>
                                            </Link>
                                        );
                                    })}
                                </>
                            ) : (
                                <Link to={section.slug}>
                                    <h3>
                                        {section.name}
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.0093 6.02451C17.0093 5.47222 16.5616 5.02451 16.0093 5.02451L7.0093 5.02456C6.45702 5.02456 6.0093 5.47228 6.00931 6.02457C6.00931 6.57685 6.45703 7.02456 7.00931 7.02456L15.0093 7.02452L15.0094 15.0245C15.0094 15.5768 15.4571 16.0245 16.0094 16.0245C16.5616 16.0245 17.0094 15.5768 17.0094 15.0245L17.0093 6.02451ZM6.03426 17.4139L16.7164 6.73161L15.3022 5.31741L4.62004 15.9997L6.03426 17.4139Z"
                                                fill={`${themeDark ? "white" : "black"}`}
                                            />
                                        </svg>
                                    </h3>
                                </Link>
                            )}
                        </ul>
                    );
                })}
            </nav>
        </div>
    );
}

export default SiteMap;
