export default class SettingsService {
    static scrollPosition: any;

    static currentPage: string;

    static prevPage: string;

    // maybe this could be setled from a config file or in some initial bootstrapping phase
    static autoScrollOnlyIn = ["tech-expertise"];

    static autoScrollEnabled = false;

    static getScrollPosition() {
        return SettingsService.scrollPosition;
    }

    static getCurrentPage() {
        return SettingsService.currentPage;
    }

    static getPrevPage() {
        return SettingsService.prevPage;
    }

    static getAutoScrollOnlyIn() {
        return SettingsService.autoScrollOnlyIn;
    }

    static autoScrollIsEnabled() {
        return SettingsService.autoScrollEnabled;
    }

    static setScrollPosition(position: any) {
        SettingsService.scrollPosition = position;
    }

    static setPrevPage(page: string) {
        SettingsService.prevPage = page;
    }

    static setCurrentPage(page: string) {
        SettingsService.prevPage = SettingsService.currentPage;
        SettingsService.currentPage = page;

        if (SettingsService.autoScrollOnlyIn.includes(page) && SettingsService.prevPage === page) SettingsService.autoScrollEnabled = true;
        else SettingsService.autoScrollEnabled = false;
    }
}
