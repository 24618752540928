// extracted by mini-css-extract-plugin
export var background = "DropdownDesktop-module--background--6a259";
export var content = "DropdownDesktop-module--content--8d05a";
export var contentInner = "DropdownDesktop-module--contentInner--e790c";
export var isActive = "DropdownDesktop-module--isActive--ce2b5";
export var mainNav = "DropdownDesktop-module--mainNav--54fa1";
export var navLink = "DropdownDesktop-module--navLink--45007";
export var navTrack = "DropdownDesktop-module--navTrack--887cf";
export var overlay = "DropdownDesktop-module--overlay--65fe2";
export var revealBg = "DropdownDesktop-module--reveal-bg--5ccaf";
export var revealContent = "DropdownDesktop-module--reveal-content--6ff3f";
export var revealOverlay = "DropdownDesktop-module--reveal-overlay--f0d14";
export var sectionNav = "DropdownDesktop-module--sectionNav--2731b";
export var wrapper = "DropdownDesktop-module--wrapper--88516";