import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query siteMetadata {
                site {
                    siteMetadata {
                        description
                        siteUrl
                        title
                        twitter {
                            card
                            creator
                            description
                            image
                            site
                            title
                        }
                        og {
                            description
                            image
                            title
                            type
                            url
                        }
                        gdpr {
                            allowButtonText
                            privacyPolicyText
                            privacyPolicyUrl
                            text
                            title
                        }
                        themes {
                            navbar
                            footer
                        }
                    }
                }
            }
        `
    );

    return site.siteMetadata;
};

export default useSiteMetadata;
