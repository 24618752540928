// extracted by mini-css-extract-plugin
export var addressContainer = "Footer-module--addressContainer--ef37d";
export var contact = "Footer-module--contact--a4b17";
export var iconContainer = "Footer-module--iconContainer--4174f";
export var internalLink = "Footer-module--internalLink--0c9ef";
export var isActive = "Footer-module--isActive--fac6f";
export var isDark = "Footer-module--isDark--4fdc0";
export var logoContainer = "Footer-module--logoContainer--61c3f";
export var mainNav = "Footer-module--mainNav--b059c";
export var mediaContainer = "Footer-module--mediaContainer--33d5d";
export var navContainer = "Footer-module--navContainer--248d0";
export var navigation = "Footer-module--navigation--03af5";
export var svgWrapper = "Footer-module--svgWrapper--d8df2";
export var wrapper = "Footer-module--wrapper--acad0";