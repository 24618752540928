import React from "react";
import PropTypes from "prop-types";
import * as styles from "./NavbarToggler.module.scss";

interface INavbarTogglerProps {
    navbarVisible: boolean;
    theme?: string;
    setNavbarVisible: (bool: boolean) => void;
    resetStates: () => void;
}

const NavbarToggler = ({ theme, navbarVisible, setNavbarVisible, resetStates }: INavbarTogglerProps) => {
    const handleClick = (navbarVisible: boolean) => {
        if (!navbarVisible) {
            setNavbarVisible(true);
        } else {
            resetStates();
        }
    };

    return (
        <button className={styles.navSwitch} onClick={() => handleClick(navbarVisible)}>
            {navbarVisible ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26">
                    <path fill="none" stroke="#FFFFFF" strokeWidth="3" d="M3.2,2l21.6,21.6" />
                    <path fill="none" stroke="#FFFFFF" strokeWidth="3" d="M25,2L3,24" />
                </svg>
            ) : (
                <svg
                    width="28"
                    height="26"
                    viewBox="0 0 28 26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={theme?.toLocaleLowerCase() === "dark" ? "black" : "white"}>
                    <rect x="0" y="20" width="28" height="3"></rect>
                    <rect x="0" y="10" width="28" height="3"></rect>
                    <rect x="0" y="0" width="28" height="3"></rect>
                </svg>
            )}
        </button>
    );
};

export default NavbarToggler;
