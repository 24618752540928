import React from "react";
import { navigate } from "gatsby";
import * as styles from "./RelatedContent.module.scss";
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from "gatsby-plugin-image";

interface IRelatedContentProps {
    heading: string;
    content: Array<IContent>;
}

export interface IContent {
    image: {
        alternativeText: string;
        file: {
            childImageSharp: {
                gatsbyImageData: IGatsbyImageData;
            };
        };
    };
    slug: string;
    heading?: string;
    hero?: {
        heading: string;
    };
    expertise?: {
        heading: string;
    };
}
const RelatedContent = ({ heading, content }: IRelatedContentProps) => {
    return (
        <section className={`${styles.relatedWrapper} px-4`}>
            <div className="mainBox">
                <h1 className="font-serif">{heading}</h1>
                <ul className={styles.relatedGrid}>
                    {content.map((x: any, i: number) => {
                        const alt = x.image.alternativeText || "software development company";

                        return (
                            <li
                                className={styles.relatedItem}
                                key={`keepreading-${i}`}
                                onClick={() => navigate(x.slug || "")}
                                onKeyPress={e => (e.key && e.key.toLowerCase() === "enter" ? navigate(x.slug || "") : null)}
                                // eslint-disable-next-line
                                role="button"
                                tabIndex={0}>
                                <h3>{x.hero?.heading || x.heading || x.expertise?.heading}</h3>

                                <div className={styles.relatedLink}>
                                    <span className={styles.linkLabel}>Learn more</span>
                                    <span className={styles.linkArrow}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.62 21.79">
                                            <polygon points="41.12 0 38.99 2.12 45.99 9.12 0 9.12 0 12.12 45.77 12.12 38.22 19.67 40.34 21.79 51.62 10.51 41.12 0" />
                                        </svg>
                                    </span>
                                </div>

                                <GatsbyImage alt={alt} image={x.image.file.childImageSharp.gatsbyImageData} className={styles.relatedImg} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};

export default RelatedContent;
