import { useStaticQuery, graphql } from "gatsby";
import slugify from "../../helpers";

//export const skipChildrenIn = ["Services", "Company"];

/**
 * Return site map data to specific component (NavBar or Footer).
 * @param {navigationComponent: string} . Component to show site data. NAVBAR/FOOTER
 * @return {IQueryFormatted: []} The query data of site map formatted according the param component.
 */

export enum types {
    DYNAMIC = "dynamic",
    MULTIPLE = "multiple",
    ONE = "one",
    LINK = "link",
}

export enum navigationComponent {
    NAVBAR = "navbar",
    SITEMAP = "sitemap",
}

export interface IDataInfo {
    name: string;
    queryAlias?: string;
    subtitle?: string;
    type?: string;
    slug?: string;
    highlighted?: boolean;
    sectionChildren?: IChildrenData[];
}

export interface IChildrenData {
    name: string;
    slug?: string;
    queryAlias?: string;
    items: IChildData[];
}

export interface IChildData {
    name: string;
    slug?: string;
}

interface IQueryData {
    nav: string;
    title?: string;
}

export interface IQueryFormatted {
    name: string;
    slug?: string;
    highlighted?: boolean;
    sectionChildren: ISectionChildren[];
}

interface ISectionChildren {
    name: string;
    child: IChildData[];
}

const navDataInfo: IDataInfo[] = [
    {
        name: "Services",
        queryAlias: "allStrapiServicePage",
        type: types.DYNAMIC,
        slug: "/services/",
        sectionChildren: [],
    },
    {
        name: "Expertise",
        type: types.MULTIPLE,
        sectionChildren: [
            {
                name: "Industries",
                queryAlias: "allStrapiIndustriesPage",
                slug: "/industries/",
                items: [],
            },
            {
                name: "Case Studies",
                queryAlias: "allStrapiCaseStudiesPage",
                slug: "/case-studies/",
                items: [],
            },
            {
                name: "Tech Expertise",
                queryAlias: "allStrapiTechExpertisesPage",
                slug: "/tech-expertise/",
                items: [],
            },
        ],
    },
    {
        name: "Company",
        type: types.ONE,
        subtitle: "About",
        slug: "/company/",
        sectionChildren: [
            {
                name: "About Us",
                queryAlias: "allStrapiAboutUsPage",
                items: [],
            },
            {
                name: "Value Analysis",
                queryAlias: "allStrapiValueAnalysisPage",
                items: [],
            },
            {
                name: "Privacy Policy",
                queryAlias: "allStrapiPrivacyPolicyPage",
                items: [],
            },
            {
                name: "Information Security Policy",
                queryAlias: "allStrapiInformationSecurityPolicyPage",
                items: [],
            },
        ],
    },
    {
        name: "Contact us",
        type: types.LINK,
        slug: "/contact-us/",
        highlighted: true,
    },
];

export default (component: navigationComponent) => {
    const navigationData = JSON.parse(JSON.stringify(navDataInfo));
    const data = useStaticQuery(
        graphql`
            query {
                allStrapiServicePage(sort: { fields: title, order: ASC }) {
                    nodes {
                        title
                        nav
                    }
                }
                allStrapiIndustriesPage(sort: { fields: title, order: ASC }) {
                    nodes {
                        nav
                        title
                    }
                }
                allStrapiCaseStudiesPage(sort: { fields: title, order: ASC }) {
                    nodes {
                        nav
                        title
                    }
                }
                allStrapiTechExpertisesPage(sort: { fields: title, order: ASC }) {
                    nodes {
                        nav
                        title
                    }
                }
                allStrapiAboutUsPage(sort: { fields: nav, order: ASC }) {
                    nodes {
                        nav
                    }
                }
                allStrapiValueAnalysisPage(sort: { fields: nav, order: ASC }) {
                    nodes {
                        nav
                    }
                }
                allStrapiPrivacyPolicyPage(sort: { fields: nav, order: ASC }) {
                    nodes {
                        nav
                    }
                }
                allStrapiInformationSecurityPolicyPage(sort: { fields: nav, order: ASC }) {
                    nodes {
                        nav
                    }
                }
            }
        `
    );

    //function that populate the parsed empty array (navigationData) with data from the query
    function setData(node: IQueryData): any {
        const slug = slugify(node.title ? node.title : node.nav);
        const name = node.nav;
        return { name, slug };
    }

    function getQueryData(queryData: IDataInfo[]): IDataInfo[] {
        //const navigationData = JSON.parse(JSON.stringify(queryData));

        navigationData.forEach((section: IDataInfo) => {
            //"dynamic" sections will add query pages data to its children
            if (section.sectionChildren && section.sectionChildren.length === 0 && section.type === types.DYNAMIC) {
                if (section.queryAlias) {
                    data[section.queryAlias].nodes.forEach((node: IQueryData) => {
                        section.sectionChildren?.push(setData(node));
                    });
                }
            }
            //"one" and "multiple" sections will add query pages data to the children of its children
            if (section.sectionChildren && section.sectionChildren.length > 0 && (section.type === types.ONE || section.type === types.MULTIPLE)) {
                section.sectionChildren.forEach((sectionChild: IChildrenData) => {
                    if (sectionChild.queryAlias) {
                        data[sectionChild.queryAlias].nodes.forEach((node: IQueryData) => {
                            sectionChild.items.push(setData(node));
                        });
                    }
                });
            }
        });

        return queryData;
    }

    function getNavHeaderData(structure: IDataInfo[]): IQueryFormatted[] {
        const queryDataPopulated = getQueryData(structure);
        const formattedData: IQueryFormatted[] = [];

        queryDataPopulated.forEach((querySection: IDataInfo) => {
            let sectionChildrenFormatted: ISectionChildren[] = [];
            let sectionChildFormatted: IChildData[] = [];

            if (querySection.type === types.DYNAMIC) {
                querySection.sectionChildren?.forEach((querySectionChild: IChildData) => {
                    const name: string = querySectionChild.name;
                    const slug: string = (querySection.slug ? querySection.slug : "") + (querySectionChild.slug ? querySectionChild.slug : "");
                    sectionChildFormatted.push({ name, slug });
                });
                sectionChildrenFormatted.push({ name: querySection.name, child: sectionChildFormatted });
                formattedData && formattedData.push({ name: querySection.name, sectionChildren: sectionChildrenFormatted });
            }

            if (querySection.type === types.MULTIPLE) {
                querySection.sectionChildren?.forEach((querySectionChild: IChildrenData) => {
                    sectionChildFormatted = [];
                    querySectionChild.items.forEach((item: IChildData) => {
                        const name: string = item.name;
                        const slug: string = (querySectionChild.slug ? querySectionChild.slug : "") + (item.slug ? item.slug : "");
                        sectionChildFormatted.push({ name, slug });
                    });
                    sectionChildrenFormatted.push({ name: querySectionChild.name, child: sectionChildFormatted });
                });
                formattedData && formattedData.push({ name: querySection.name, sectionChildren: sectionChildrenFormatted });
            }

            if (querySection.type === types.ONE) {
                querySection.sectionChildren?.forEach((querySectionChild: IChildrenData) => {
                    querySectionChild.items.forEach((item: IChildData) => {
                        const name: string = item.name;
                        const slug: string = (querySection.slug ? querySection.slug : "") + (item.slug ? item.slug : "");
                        sectionChildFormatted.push({ name, slug });
                    });
                });
                sectionChildrenFormatted.push({ name: querySection.subtitle!, child: sectionChildFormatted });
                formattedData && formattedData.push({ name: querySection.name, sectionChildren: sectionChildrenFormatted });
            }

            if (querySection.type === types.LINK) {
                const name: string = querySection.name;
                const slug: string = querySection.slug ? querySection.slug : "";
                const highlighted = true;

                formattedData.push({ name, slug, highlighted, sectionChildren: [] });
            }
        });
        return formattedData;
    }

    if (component === navigationComponent.NAVBAR) return getNavHeaderData(navigationData);
    else return getQueryData(navigationData);
};
