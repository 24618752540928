import React from "react";
import * as styles from "./Banner.module.scss";

const Banner = ({ shortBanner }: any) => (
    <section className={`${styles.wrapper} textBox px-2`}>
        <div className={styles.inner}>
            {shortBanner &&
                shortBanner.map((info: any) => (
                    <div key={info.title}>
                        <h3>{info.title}</h3>
                        <p>{info.text}</p>
                    </div>
                ))}
        </div>
    </section>
);

export default Banner;
