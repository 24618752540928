import React from "react";
import { ProgressiveImageSwitch } from "../ProgressiveImage";
import * as styles from "./HeroImage.module.scss";

interface IHeroImageProps {
    background: object | null;
    backgroundMobile: object | null;
    heading: string;
    subHeading: string;
    isSerif: boolean;
}

const HeroImage = ({ background, backgroundMobile, heading, subHeading, isSerif }: IHeroImageProps) => (
    <section className={`${styles.wrapper} ${isSerif ? styles.isSerif : ""}`}>
        {(background || backgroundMobile) && <ProgressiveImageSwitch image={background} mobileImage={backgroundMobile} />}

        <div className="mainBox">
            <div className={styles.inner}>
                <h1 className={isSerif ? "font-serif" : ""}>{heading}</h1>
                <div dangerouslySetInnerHTML={{ __html: subHeading }} />
            </div>
        </div>
    </section>
);

export default HeroImage;
