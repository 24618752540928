import { useState, useLayoutEffect, useRef } from "react";
import SettingsService from "../services/SettingsService";

/**
 * Enumerates the possible scroll directions.
 */
export const ScrollDirection = Object.freeze({
    None: 0,
    Up: 1,
    Down: 2,
});

/**
 * Detects scroll changes over a given HTML element.
 * @param {HTMLElement} element The html element to look for scroll changes.
 * @return {{ position:{ x: number, y: number }, positionChanged: boolean, direction: number, directionChanged: boolean }} The scroll position information.
 * @see {ScrollDirection}
 */
const useScrollListener = (element: any) => {
    const defaultPositionValues = {
        position: { x: element.scrollX, y: element.scrollY },
        positionChanged: false,
        direction: ScrollDirection.None,
        directionChanged: false,
        autoScrollEnabled: SettingsService.autoScrollIsEnabled(),
    };
    const [scrollInfo, setScrollInfo] = useState(defaultPositionValues);
    const firstRender = useRef(false);

    useLayoutEffect(() => {
        const handleScroll = () => {
            const { position: lastPosition, direction: lastDirection } = scrollInfo;
            const currentDirection = lastPosition.y > element.scrollY ? ScrollDirection.Up : ScrollDirection.Down;
            const currentPosition = { x: element.scrollX, y: element.scrollY };

            const currentScrollInfo = {
                position: currentPosition,
                positionChanged: currentPosition.x !== lastPosition.x || currentPosition.y !== lastPosition.y,
                // we set direction to DOWN if we are in a autoScroll page and in the first render
                // then we set direction as normal
                direction: SettingsService.autoScrollIsEnabled() && !firstRender.current ? 2 : currentDirection,
                directionChanged: currentDirection !== lastDirection,
                autoScrollEnabled: SettingsService.autoScrollIsEnabled(),
            };

            SettingsService.setScrollPosition(lastPosition);
            setScrollInfo(currentScrollInfo);

            if (!firstRender.current) {
                firstRender.current = true;
            }
        };

        element.addEventListener("scroll", handleScroll);
        return () => element.removeEventListener("scroll", handleScroll);
    }, [scrollInfo, element]);

    return scrollInfo;
};

export default useScrollListener;
