import React from "react";
import { Link } from 'gatsby';
import * as styles from "./DropdownMobile.module.scss";

interface IDropdownMobileProps {
    content?: [],
    setNavbarVisible?: any,
    isActive?: boolean,
    setDropdownContent?: any,
    handleClick: any

}

function DropdownMobile({ content, setNavbarVisible, isActive }: IDropdownMobileProps) {

    return (
        <>
            {isActive && content && (
                content.map((section: any) => (
                    <div key={section.name} className={styles.navMenu}>
                        <span className={styles.navbarMargin}>
                            {section.name}
                        </span>
                        {section.child.map((item: any, i: number) => (
                            <Link
                                key={item.name}
                                className={`${styles.navbarMargin} ${styles.navLink}`}
                                style={{
                                    animationDelay: `${(i + 1) * 60}ms`
                                }}
                                to={item.slug}
                                onClick={() => setNavbarVisible(false)}
                                tabIndex={0}
                                role="link"
                            >
                                {item.name}
                            </Link>
                        ))}
                    </div>
                ))
            )}
        </>
    )
}


export default DropdownMobile;
