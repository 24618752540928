import React, { useState } from "react";
import useWindowWidth from "../../../hooks/useWindowWidth";
import * as styles from "./ProgressiveImage.module.scss";

import { GatsbyImage } from "gatsby-plugin-image";

interface IProgressiveImageProps {
    className: string;
    image: any;
}

interface IProgressiveImageSwitchProps {
    mobileImage: any;
    image: any;
}
const ProgressiveImage = ({ className, image }: IProgressiveImageProps) => {
    const alt = image.alternativeText || "software development company";

    return <GatsbyImage alt={alt} image={image.file.childImageSharp.gatsbyImageData} className={`${className} ${styles.mainImage} ${styles.loaded}`} />;
};

export const ProgressiveImageSwitch = ({ image, mobileImage }: IProgressiveImageSwitchProps) => {
    const isDesktop = useWindowWidth(768);

    return isDesktop !== undefined ? (
        <ProgressiveImage className={isDesktop ? styles.coverImage : styles.coverImageMobile} image={isDesktop ? image : mobileImage} />
    ) : null;
};

export default ProgressiveImage;
