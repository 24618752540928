declare global {
    interface Window {
        gtag: any;
    }
}
class EventTrackerService {
    codes: any;
    pagesToTrack: any;

    constructor() {
        this.codes = {
            value_analysis: "vafxCNbimeMBEL2qppEC",
            contact: "WGxGCIj92O4BEL2qppEC",
        };
        this.pagesToTrack = ["contact us"];
    }

    buttonClick(name: string, sendConversion?: boolean) {
        try {
            name = name.toLowerCase().replaceAll(" ", "_");

            window.gtag("event", `click_${name}`, {
                origin: window.location.pathname,
            });
            if (sendConversion) {
                this.gtag_report_conversion(name);
            }
        } catch {
            console.log("buttonClick(): window.gtag got an error or doesn't exist");
        }
    }

    formSubmit(name: string, sendConversion: boolean) {
        try {
            name = name.toLowerCase().replaceAll(" ", "_");
            window.gtag("event", `submit_${name}`, {
                origin: window.location.pathname,
            });
            if (sendConversion) {
                this.gtag_report_conversion(name);
            }
        } catch {
            console.log("formSubmit(): window.gtag got an error or doesn't exist");
        }
    }

    gtag_report_conversion(codeName: string, url?: any) {
        const callback = () => {
            if (typeof url !== "undefined") {
                window.location = url;
            }
        };
        try {
            window.gtag("event", "conversion", {
                send_to: `AW-573150525/${this.codes[codeName]}`,
                event_callback: callback,
            });
        } catch {
            console.log("gtag_report_conversion(): Conversion failed");
        }
    }
}

export default new EventTrackerService();
