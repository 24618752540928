import { Link } from "gatsby";
import React from "react";
import EventTrackerService from "../../../../services/EventTrackerService";
import { IQueryFormatted } from "../../navData";
import * as styles from "./NavbarDesktop.module.scss";

interface INavbarDesktopProps {
    navData?: any[];
    dropdownOpen?: boolean;
    dropdownContent?: any;
    handleClick?: any;
    theme?: string;
}

const NavbarDesktop = ({ theme, navData, dropdownOpen, dropdownContent, handleClick }: INavbarDesktopProps) => {
    return (
        <nav>
            {navData &&
                navData.map((elem: IQueryFormatted) => {
                    return (
                        <button
                            className={` ${styles.button} ${theme?.toLocaleLowerCase() === "dark" && !dropdownOpen ? styles.isDark : ""} ${
                                elem.highlighted ? styles.isHighlighted : ""
                            } ${dropdownOpen && dropdownContent?.name === elem.name ? styles.isActive : ""}`}
                            key={elem.name}
                            onClick={() => handleClick(elem.name)}>
                            <span>{elem.name}</span>
                            {elem.sectionChildren.length > 0 && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 10 6" height="6">
                                    <polygon points="9.03,0 5,4.06 0.97,0 0,0.94 5,6 10,0.94 " />
                                </svg>
                            )}
                        </button>
                    );
                })}
        </nav>
    );
};

export default NavbarDesktop;
