import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import MiracleLogo from "../../Shared/MiracleLogo";
import * as styles from "./Header.module.scss";
import useScrollListener, { ScrollDirection } from "../../../hooks/useScrollListener";
import Navbar from "../Navbar";
import NavbarToggler from "./NavbarToggler";
import DropdownDesktop from "../Dropdown/DropdownDesktop";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { IDataInfo } from "../navData";
import useFocusTrap from "../../../hooks/useFocusTrap";
import useEventListener from "../../../hooks/useEventListener";

interface IHeaderProps {
    theme?: string;
}

const isBrowser = typeof window !== "undefined";

const Header = ({ theme }: IHeaderProps) => {
    // If window is undefined (during build), send default position to avoid compiling errors
    const { position, direction } = useScrollListener(isBrowser ? window : { scrollX: 0, scrollY: 0 });
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownContent, setDropdownContent] = useState<IDataInfo | null>(null);
    const [currentSubSection, setCurrentSubSection] = useState<number>(0);

    const isDesktop = useWindowWidth(992);
    const navBarRef = useRef<any>();
    const hasDarkTheme = theme?.toLocaleLowerCase() === "dark" && (isDesktop ? !dropdownOpen : !navbarVisible);

    useFocusTrap(navBarRef, "a[href], button:not([disabled])", dropdownOpen, dropdownContent, currentSubSection);

    const handleKeyClose = (e: KeyboardEvent): void => {
        if (e.keyCode === 27) setDropdownOpen(false);
    };

    useEventListener("keydown", handleKeyClose);

    useEffect(() => {
        setNavbarVisible(isDesktop);
        setDropdownOpen(false);
        setDropdownContent(null);
    }, [isDesktop]);

    const resetStates = () => {
        setNavbarVisible(isDesktop);
        setDropdownOpen(false);
        setDropdownContent(null);
    };

    if (isBrowser) {
        let HeaderVisibleOffset = isDesktop ? 100 : 50;
        if (position.y < HeaderVisibleOffset) {
            document.documentElement.classList.remove("navbarIsVisible");
            document.documentElement.classList.remove("navbarIsSticky");
        } else {
            document.documentElement.classList.add("navbarIsSticky");

            if (direction === ScrollDirection.Up) document.documentElement.classList.add("navbarIsVisible");
            else if (direction === ScrollDirection.Down) document.documentElement.classList.remove("navbarIsVisible");
        }
    }

    return (
        <>
            <div ref={navBarRef}>
                {navbarVisible && <div className={styles.logoBackground} />}
                <header className={`${styles.wrapper} ${hasDarkTheme ? styles.isDark : ""}`}>
                    <div className={`mainBox ${styles.inner}`}>
                        <div className={styles.logoContainer}>
                            <Link to="/" onClick={() => resetStates()}>
                                <MiracleLogo isColored={true} hasWordmark={true} theme={hasDarkTheme ? "#102641" : "#FFF"} />
                            </Link>
                        </div>

                        {!isDesktop && (
                            <NavbarToggler setNavbarVisible={setNavbarVisible} resetStates={resetStates} theme={theme} navbarVisible={navbarVisible} />
                        )}

                        {navbarVisible && (
                            <Navbar
                                theme={theme}
                                isDesktop={isDesktop}
                                dropdownOpen={dropdownOpen}
                                dropdownContent={dropdownContent}
                                setDropdownContent={setDropdownContent}
                                setDropdownOpen={setDropdownOpen}
                                setNavbarVisible={setNavbarVisible}
                            />
                        )}
                    </div>
                </header>
                {isDesktop && dropdownOpen && dropdownContent && (
                    <DropdownDesktop content={dropdownContent.sectionChildren} setDropdownOpen={setDropdownOpen} setCurrentSubSection={setCurrentSubSection} />
                )}
            </div>
        </>
    );
};

export default Header;
