import React, { useState, useEffect, useRef } from "react";
import { Link } from 'gatsby';
import usePrevious from '../../../../hooks/usePrevious';
import * as styles from "./DropdownDesktop.module.scss";
import EventTrackerService from "../../../../services/EventTrackerService";

interface IDropdownDesktopProps {
    //content: IChildrenData[] | IChildData[] | undefined,
    content: any,
    setDropdownOpen?: any,
    setCurrentSubSection?: any
}

function DropdownDesktop({ content, setDropdownOpen, setCurrentSubSection}: IDropdownDesktopProps) {

    const [contentDelay, setContentDelay] = useState(400);
    const [trackProperties, setTrackProperties] = useState({});
    const [current, setCurrent] = useState(0);
    const isFirstRender = useRef(true);
    const navWrapper = useRef<HTMLElement>(null);
    const navItems = useRef<any>([]);
    const prevContent = usePrevious(content);
    const prevCurrent = usePrevious(current);

    useEffect(() => {
        document.body.classList.add("dropdownOpen");
        return () => {
            document.body.classList.remove("dropdownOpen");
            document.body.classList.add("dropdownIsClosing");

            setTimeout(() => {
                document.body.classList.remove("dropdownIsClosing");
            }, 200)
        };
        
    }, []);

    useEffect(() => {
        function setNavTrack(isInitial?: boolean) {
            let activeItem: any;
    
            if (isInitial) {
                activeItem = navItems.current[0];
            } else {
                for (let i = 0; i < navItems.current.length; i++) {
                    if (i === current) {
                        activeItem = navItems.current[i];
                    }
                }
            }

            if(activeItem){
                let { left, width } = activeItem?.getBoundingClientRect();
                let { left: navLeft } = (navWrapper.current as any).getBoundingClientRect();
                setTrackProperties({
                    trackLeft: left - navLeft,
                    trackWidth: width
                });
            }
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
            setNavTrack(true);
            return;
        }

        if (content !== prevContent) {
            if (current === 0) {
                setNavTrack(true);
                return;
            }
    
            setCurrent(0)
            setCurrentSubSection(0);
        }
        
        if (current !== prevCurrent) {
            setNavTrack();
        }

        if (contentDelay === 0) return;

        setContentDelay(0);
    }, [content, current, contentDelay, prevCurrent, prevContent]);

    const handleNavigation = (linkName: any) => {
        const eventTracker = EventTrackerService;
        if (eventTracker.pagesToTrack.some((page: any) => page === linkName.toLowerCase())) {
            eventTracker.buttonClick(linkName);
        }
        setDropdownOpen(false);
    }

    return (
        <div
            className={styles.wrapper}
            style={{
                ["--contentDelay" as string]: contentDelay,
                ["--trackLeft" as string]: (trackProperties as any).trackLeft,
                ["--trackWidth" as string]: (trackProperties as any).trackWidth
            }}
        >
            <div className={styles.overlay} role="presentation" onClick={() => setDropdownOpen(false)} />

            <div className={styles.background} />

            <div className={styles.content}>
                <div className={`mainBox ${styles.contentInner}`}>
                    <nav className={styles.sectionNav} ref={navWrapper}>
                        {content && content.map((elem: any, i: number) => (
                            <button
                                key={elem.name}
                                onClick={() => {
                                    setCurrent(i);
                                    setCurrentSubSection(i);
                                }}
                                className={i === current ? styles.isActive : ""}
                                ref={el => (navItems.current[i] = el)}
                            >
                                <span>{elem.name}</span>
                            </button>
                        ))}
                        <div className={styles.navTrack} />
                    </nav>

                    <nav className={styles.mainNav}>
                   
                        
                        {content && content[current] && content[current].child?.map((item: any, i: number) => (
                            <div key={item.name}>
                                <Link
                                    className={styles.navLink}
                                    activeClassName={styles.isActive}
                                    style={{
                                        animationDelay: `${contentDelay + (i + 1) * 100}ms`
                                    }}
                                    to={item.slug}
                                    onClick={()=> handleNavigation(item.name)}
                                >
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
}


export default DropdownDesktop;
