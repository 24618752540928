import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import MiracleLogo from "../../Shared/MiracleLogo";
import * as styles from "./Footer.module.scss";
import SiteMap from "./SiteMap";

interface IFooterProps {
    theme?: string;
    currentPath: string;
}

function Footer({ theme, currentPath }: IFooterProps) {
    const data = useStaticQuery(
        graphql`
            query Footer {
                strapiFooterModule {
                    address
                    copyright
                    social {
                        logo
                        url
                    }
                }
            }
        `
    );

    const { strapiFooterModule } = data;

    return (
        <section className={[styles.wrapper, theme?.toLocaleLowerCase() === "dark" ? styles.isDark : ""].join(" ")}>
            <div className="mainBox">
                <SiteMap themeDark={theme?.toLocaleLowerCase() === "dark"} currentPath={currentPath ? currentPath : ""} />
                <div className={`${styles.contact} px-4`}>
                    <div className={styles.logoContainer}>
                        <MiracleLogo isColored={false} hasWordmark={true} theme={theme?.toLocaleLowerCase() === "dark" ? "#FFF" : "#102641"} />
                    </div>

                    <div className={styles.addressContainer}>
                        <div className={styles.svgWrapper}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30px" height="30px">
                                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                            </svg>
                        </div>
                        <span>{strapiFooterModule.address}</span>
                    </div>

                    <div className={styles.mediaContainer}>
                        <span>{strapiFooterModule.copyright}</span>
                        <div className={styles.iconContainer}>
                            {strapiFooterModule.social &&
                                strapiFooterModule.social.map((x: any) => (
                                    <div className={styles.svgWrapper} key={x.url}>
                                        <a href={x.url} target="_blank" rel="noopener noreferrer">
                                            <span dangerouslySetInnerHTML={{ __html: x.logo }} />
                                        </a>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;
