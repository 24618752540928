// extracted by mini-css-extract-plugin
export var button = "Header-module--button--ce267";
export var hideBackground = "Header-module--hide-background--0679c";
export var inner = "Header-module--inner--e8fe1";
export var isDark = "Header-module--isDark--5a55e";
export var logoBackground = "Header-module--logoBackground--00c67";
export var logoContainer = "Header-module--logoContainer--89eaf";
export var navSwitch = "Header-module--navSwitch--64dfe";
export var navbarVisible = "Header-module--navbarVisible--865d7";
export var revealBackground = "Header-module--reveal-background--27543";
export var revealContent = "Header-module--reveal-content--ce1ac";
export var wrapper = "Header-module--wrapper--44432";