import React, { useEffect } from "react";
import "../../stylesheets/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import useSiteMetadata from "./SiteMetadata";

function Layout(props: any) {
    const { pageContext, children, location } = props;

    const { themes } = useSiteMetadata();

    useEffect(() => document.documentElement.style.setProperty("--scrollbarWidth", `${window.innerWidth - document.documentElement.clientWidth}px`), []);
    return (
        <>
            <Header theme={pageContext?.themes?.navbar ? pageContext.themes.navbar : themes.navbar} />
            {children}
            <Footer theme={pageContext?.themes?.footer ? pageContext.themes.footer : themes.footer} currentPath={location.pathname} />
        </>
    );
}

export default Layout;
