import React, { useEffect } from "react";
import { Link } from "gatsby";
import * as styles from "./NavbarMobile.module.scss";
import DropdownMobile from "../../Dropdown/DropdownMobile";
import EventTrackerService from "../../../../services/EventTrackerService";

interface INavbarProps {
    navData?: any[];
    dropdownOpen?: boolean;
    dropdownContent?: any;
    handleClick?: any;
    setDropdownContent?: any;
    setNavbarVisible?: any;
}

function NavbarMobile({ navData, dropdownOpen, dropdownContent, handleClick, setDropdownContent, setNavbarVisible }: INavbarProps) {
    useEffect(() => {
        document.body.classList.add("mobileDropdownOpen");
        return () => {
            document.body.classList.remove("mobileDropdownOpen");
            setDropdownContent(null);
        };
    }, [setDropdownContent]);

    const handleLinkClick = (buttonName: string) => {
        EventTrackerService.buttonClick(buttonName);
        setNavbarVisible(false);
    };

    return (
        <nav className={styles.navbarMobile}>
            {navData &&
                navData.map((section: any) => {
                    let isActive = dropdownContent?.name === section.name && dropdownOpen;

                    return section.sectionChildren.length > 0 ? (
                        <React.Fragment key={section.name}>
                            <button className={`${styles.button} ${isActive ? styles.isActive : ""}`} onClick={() => handleClick(section.name)}>
                                <span>{section.name}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 10 6" height="6">
                                    <polygon points="9.03,0 5,4.06 0.97,0 0,0.94 5,6 10,0.94 " />
                                </svg>
                            </button>
                            <DropdownMobile
                                content={section.sectionChildren}
                                setDropdownContent={setDropdownContent}
                                handleClick={handleClick}
                                setNavbarVisible={setNavbarVisible}
                                isActive={isActive}
                            />
                        </React.Fragment>
                    ) : (
                        <Link
                            key={section.name}
                            className={`${styles.button} ${styles.isActive}`}
                            to={section.slug}
                            onClick={() => handleLinkClick(section.name)}>
                            {section.name}
                        </Link>
                    );
                })}
        </nav>
    );
}

export default NavbarMobile;
