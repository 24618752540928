exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-us-page-about-us-page-template-tsx": () => import("./../../../src/templates/AboutUsPage/AboutUsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-about-us-page-about-us-page-template-tsx" */),
  "component---src-templates-contact-us-page-contact-us-page-template-tsx": () => import("./../../../src/templates/ContactUsPage/ContactUsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-us-page-contact-us-page-template-tsx" */),
  "component---src-templates-industries-page-industries-page-template-tsx": () => import("./../../../src/templates/IndustriesPage/IndustriesPageTemplate.tsx" /* webpackChunkName: "component---src-templates-industries-page-industries-page-template-tsx" */),
  "component---src-templates-information-security-page-information-security-page-template-tsx": () => import("./../../../src/templates/InformationSecurityPage/InformationSecurityPageTemplate.tsx" /* webpackChunkName: "component---src-templates-information-security-page-information-security-page-template-tsx" */),
  "component---src-templates-not-found-page-not-found-page-template-tsx": () => import("./../../../src/templates/NotFoundPage/NotFoundPageTemplate.tsx" /* webpackChunkName: "component---src-templates-not-found-page-not-found-page-template-tsx" */),
  "component---src-templates-privacy-policy-page-privacy-policy-page-template-tsx": () => import("./../../../src/templates/PrivacyPolicyPage/PrivacyPolicyPageTemplate.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-privacy-policy-page-template-tsx" */),
  "component---src-templates-services-page-services-page-template-tsx": () => import("./../../../src/templates/ServicesPage/ServicesPageTemplate.tsx" /* webpackChunkName: "component---src-templates-services-page-services-page-template-tsx" */),
  "component---src-templates-studies-page-studies-page-template-tsx": () => import("./../../../src/templates/StudiesPage/StudiesPageTemplate.tsx" /* webpackChunkName: "component---src-templates-studies-page-studies-page-template-tsx" */),
  "component---src-templates-tech-expertise-page-tech-expertise-page-template-tsx": () => import("./../../../src/templates/TechExpertisePage/TechExpertisePageTemplate.tsx" /* webpackChunkName: "component---src-templates-tech-expertise-page-tech-expertise-page-template-tsx" */),
  "component---src-templates-thank-you-contact-page-thank-you-contact-page-template-tsx": () => import("./../../../src/templates/ThankYouContactPage/ThankYouContactPageTemplate.tsx" /* webpackChunkName: "component---src-templates-thank-you-contact-page-thank-you-contact-page-template-tsx" */),
  "component---src-templates-value-analysis-page-value-analysis-page-template-tsx": () => import("./../../../src/templates/ValueAnalysisPage/ValueAnalysisPageTemplate.tsx" /* webpackChunkName: "component---src-templates-value-analysis-page-value-analysis-page-template-tsx" */)
}

