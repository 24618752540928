const React = require("react");
const Layout = require("./src/components/Layout").default;
const TechExpertiseModule = require("./src/components/TechExpertiseLayout").default;

exports.onRouteUpdate = ({ location }: any): void => {
    const SettingsService = require("./src/services/SettingsService").default;
    const getBasePath = (url: string) => url.split("/")[1];
    SettingsService.setCurrentPage(getBasePath(location.pathname));
};

exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }: any): boolean => {
    const { location } = routerProps;
    return !((prevRouterProps ? prevRouterProps.location.pathname.includes("tech-expertise") : false) && location.pathname.includes("tech-expertise"));
};

exports.wrapPageElement = ({ element, props }: any): any => {
    // TODO: review if this can be generalized
    const data = props.location.pathname.includes("tech-expertise") ? <TechExpertiseModule children={element} pageContext={props.pageContext} /> : element;

    return <Layout {...props}>{data}</Layout>;
};
