import React from "react";
import * as styles from "./MiracleLogo.module.scss";

function MiracleLogo({ isColored, hasWordmark, theme }: any) {
    return (
        <div
            className={[styles.wrapper, hasWordmark ? styles.displayWordmark : "", isColored ? styles.isColored : styles.isSingleColor].join(" ")}
            style={{ ["--theme" as string]: theme }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 33">
                <g className={styles.iconSingleColor}>
                    <path
                        d="M11.98,8.59L0,0.01v12.51C0,12.52,11.98,8.59,11.98,8.59z M44.09,0.01L0,14.21v17.28
                        c0,0.21,0.32,0.42,0.53,0.21L44.09,0.01z M44.09,2.02L21.84,32.76c-0.11,0.11-0.21,0.11-0.21,0l-6.15-9.75L44.09,2.02z
                        M31.16,22.69l12.51,9.12c0.21,0.21,0.53,0,0.53-0.21V4.67C44.2,4.67,31.16,22.69,31.16,22.69z"
                    />
                </g>
                <g className={styles.iconMain}>
                    <path fill="#5FC4EA" d="M44.2,0v0.63L32.54,23.77l11.12,8.12c0.22,0.16,0.54,0.01,0.54-0.25V0z" />
                    <polygon fill="#54A7C7" points="29.67,21.68 32.54,23.77 44.2,0.63 44.2,0" />
                    <path fill="#D3544C" d="M43.26,1.4L16.88,25.18l5.1,7.6c0.06,0.09,0.2,0.09,0.26,0L43.26,1.4z" />
                    <path fill="#48B8AF" d="M44.2,0L0,14.32v17.33c0,0.26,0.32,0.41,0.54,0.25L44.2,0L44.2,0z" />
                    <polygon fill="#B74B41" points="14.53,21.68 16.88,25.18 43.26,1.4 44.2,0" />
                    <polygon fill="#3EA39C" points="0,0 0,14.32 13.58,9.92" />
                </g>
                <g className={styles.wordmark}>
                    <path d="M59.02,23.56V8.4h3.14l4.48,10.09L70.67,8.4h3.16v15.16h-3.02v-8.48l-3.66,8.57h-1.09l-4.02-8.66v8.57H59.02z" />
                    <path
                        d="M78.08,11.36c-0.59,0-1.03-0.17-1.33-0.5s-0.44-0.7-0.44-1.11c0-0.39,0.15-0.76,0.44-1.09
                        c0.3-0.33,0.74-0.5,1.33-0.5s1.03,0.17,1.33,0.5s0.44,0.7,0.44,1.09c0,0.41-0.15,0.78-0.44,1.11S78.67,11.36,78.08,11.36z
                        M76.61,23.56V12.61h2.95v10.95H76.61z"
                    />
                    <path
                        d="M82.06,23.56V12.61h2.7l0.14,0.95c0.41-0.44,0.82-0.75,1.24-0.92s0.85-0.26,1.31-0.26
                        c0.26,0,0.53,0.03,0.82,0.09s0.58,0.14,0.89,0.25l-0.52,2.57c-0.3-0.12-0.59-0.22-0.85-0.28c-0.27-0.07-0.53-0.1-0.78-0.1
                        c-0.42,0-0.81,0.1-1.15,0.3c-0.34,0.2-0.61,0.42-0.81,0.68v7.68h-2.99V23.56z"
                    />
                    <path
                        d="M95.88,23.56l-0.14-0.82c-0.32,0.33-0.72,0.59-1.22,0.78c-0.49,0.19-1.03,0.28-1.62,0.28
                        c-0.48,0-0.96-0.08-1.42-0.24s-0.87-0.39-1.22-0.68c-0.35-0.3-0.63-0.66-0.84-1.09s-0.32-0.92-0.32-1.47s0.11-1.03,0.34-1.44
                        c0.23-0.42,0.53-0.77,0.91-1.06c0.38-0.29,0.81-0.52,1.31-0.7c0.49-0.18,1.01-0.32,1.56-0.41c0.38-0.08,0.8-0.13,1.25-0.17
                        s0.83-0.07,1.14-0.1v-0.36c0-0.44-0.15-0.8-0.45-1.07c-0.3-0.27-0.66-0.41-1.07-0.41c-0.26,0-0.58,0.07-0.98,0.2
                        c-0.39,0.14-0.77,0.39-1.14,0.77l-2.02-1.41c0.29-0.36,0.62-0.67,0.99-0.91c0.37-0.24,0.75-0.43,1.14-0.57
                        c0.39-0.14,0.77-0.23,1.15-0.28c0.38-0.05,0.73-0.08,1.05-0.08c0.64,0,1.22,0.08,1.75,0.24s0.99,0.41,1.37,0.75s0.69,0.77,0.9,1.3
                        c0.21,0.52,0.32,1.16,0.32,1.9v7.04h-2.74V23.56z M95.63,18.45c-0.3,0.02-0.62,0.04-0.97,0.08c-0.34,0.04-0.66,0.09-0.97,0.15
                        c-0.64,0.12-1.08,0.33-1.33,0.63s-0.37,0.62-0.37,0.97c0,0.36,0.14,0.66,0.41,0.88c0.27,0.22,0.67,0.33,1.2,0.33
                        c0.38,0,0.75-0.08,1.1-0.23c0.36-0.15,0.66-0.34,0.92-0.57v-2.24H95.63z"
                    />
                    <path
                        d="M110.37,20.92c-0.47,0.98-1.1,1.71-1.9,2.18s-1.72,0.7-2.78,0.7c-0.7,0-1.37-0.12-2.01-0.36
                        s-1.21-0.6-1.7-1.08s-0.89-1.07-1.18-1.77c-0.3-0.7-0.44-1.53-0.44-2.47c0-0.97,0.14-1.82,0.43-2.55s0.67-1.33,1.15-1.82
                        c0.48-0.48,1.03-0.84,1.65-1.08c0.62-0.23,1.26-0.35,1.93-0.35s1.26,0.09,1.78,0.28s0.98,0.44,1.36,0.74
                        c0.39,0.3,0.7,0.64,0.95,1.02s0.44,0.76,0.58,1.14l-2.34,1.16c-0.26-0.73-0.58-1.24-0.97-1.53s-0.82-0.44-1.31-0.44
                        c-0.3,0-0.59,0.07-0.86,0.2c-0.27,0.14-0.52,0.34-0.74,0.61c-0.22,0.27-0.39,0.62-0.52,1.05c-0.13,0.42-0.19,0.93-0.19,1.52
                        c0,1.05,0.23,1.86,0.69,2.43c0.46,0.58,1.06,0.86,1.81,0.86c0.58,0,1.05-0.14,1.41-0.43s0.69-0.7,0.98-1.23L110.37,20.92z"
                    />
                    <path d="M111.65,23.56V8.4h2.98v15.16H111.65z" />
                    <path
                        d="M119.42,18.79c0.08,0.92,0.36,1.59,0.85,2s1.04,0.61,1.65,0.61c0.53,0,1.01-0.09,1.43-0.28s0.86-0.6,1.32-1.24
                        l2.2,1.34c-0.62,0.95-1.34,1.62-2.16,2.01c-0.82,0.39-1.78,0.58-2.89,0.58c-0.73,0-1.42-0.11-2.07-0.34s-1.22-0.58-1.72-1.06
                        c-0.49-0.48-0.88-1.07-1.17-1.78c-0.29-0.71-0.43-1.56-0.43-2.55c0-0.92,0.14-1.74,0.42-2.45s0.66-1.31,1.15-1.8
                        c0.48-0.48,1.05-0.86,1.69-1.11c0.64-0.26,1.33-0.39,2.06-0.39c1.53,0,2.76,0.53,3.69,1.6s1.41,2.69,1.44,4.85L119.42,18.79
                        L119.42,18.79z M123.87,16.76c-0.06-0.73-0.31-1.27-0.74-1.64c-0.43-0.36-0.89-0.55-1.37-0.55c-0.26,0-0.51,0.05-0.76,0.15
                        c-0.25,0.1-0.48,0.24-0.69,0.43c-0.21,0.19-0.39,0.42-0.53,0.69s-0.24,0.58-0.28,0.91h4.37V16.76z"
                    />
                    <path
                        d="M128.96,23.56V8.4h3.91c0.59,0,1.2,0.05,1.84,0.15c0.64,0.1,1.23,0.28,1.77,0.53c0.55,0.26,1.05,0.59,1.5,0.99
                        s0.84,0.88,1.17,1.44c0.33,0.56,0.58,1.19,0.77,1.9c0.19,0.7,0.28,1.49,0.28,2.35c0,1.06-0.12,2.01-0.36,2.85s-0.58,1.57-1.01,2.19
                        s-0.94,1.14-1.53,1.55s-1.23,0.71-1.91,0.91c-0.35,0.11-0.76,0.18-1.24,0.23c-0.48,0.05-0.91,0.07-1.28,0.07
                        C132.87,23.56,128.96,23.56,128.96,23.56z M130.78,21.99h1.86c0.33,0,0.71-0.02,1.14-0.06c0.42-0.04,0.81-0.12,1.16-0.26
                        c0.45-0.2,0.88-0.45,1.28-0.77s0.76-0.71,1.07-1.17c0.31-0.46,0.55-1.01,0.73-1.65c0.17-0.64,0.26-1.38,0.26-2.23
                        c0-1.32-0.2-2.38-0.6-3.18c-0.4-0.8-0.95-1.43-1.65-1.89c-0.44-0.29-0.96-0.5-1.56-0.63c-0.6-0.13-1.18-0.19-1.74-0.19h-1.95V21.99
                        z"
                    />
                    <path
                        d="M143.53,18.04c0,1.36,0.31,2.43,0.93,3.2s1.52,1.16,2.7,1.16c0.68,0,1.26-0.17,1.73-0.5s0.85-0.74,1.14-1.23
                        l1.16,0.77c-0.39,0.68-0.91,1.25-1.53,1.69c-0.63,0.45-1.47,0.67-2.53,0.67c-0.82,0-1.55-0.12-2.2-0.37s-1.21-0.61-1.67-1.09
                        c-0.46-0.48-0.82-1.07-1.07-1.78c-0.25-0.71-0.37-1.54-0.37-2.48c0-0.86,0.12-1.64,0.35-2.33c0.23-0.69,0.56-1.28,0.98-1.77
                        c0.42-0.49,0.92-0.87,1.52-1.14c0.6-0.26,1.26-0.4,1.99-0.4c0.8,0,1.5,0.14,2.1,0.41c0.6,0.27,1.09,0.66,1.49,1.15
                        c0.39,0.49,0.69,1.08,0.89,1.77c0.2,0.69,0.3,1.44,0.3,2.26h-7.91V18.04z M149.43,16.65c-0.02-0.47-0.11-0.88-0.27-1.24
                        c-0.17-0.36-0.38-0.65-0.64-0.89c-0.26-0.23-0.55-0.41-0.89-0.52c-0.33-0.11-0.67-0.17-1-0.17c-0.73,0-1.35,0.22-1.87,0.65
                        c-0.52,0.43-0.88,1.16-1.08,2.17H149.43z"
                    />
                    <path d="M155.8,23.56l-4.07-10.84h1.89l3.23,8.84l3.25-8.84h1.77l-4.07,10.84H155.8z" />
                    <path
                        d="M163.52,20.88c0.26,0.48,0.59,0.86,0.99,1.12c0.4,0.27,0.94,0.4,1.62,0.4c1.48,0,2.23-0.53,2.23-1.59
                        c0-0.32-0.05-0.59-0.15-0.81c-0.1-0.22-0.26-0.42-0.48-0.59s-0.5-0.34-0.85-0.5s-0.77-0.33-1.27-0.51
                        c-0.55-0.18-1.01-0.38-1.39-0.58s-0.69-0.43-0.93-0.67c-0.24-0.24-0.42-0.51-0.53-0.82c-0.11-0.3-0.17-0.66-0.17-1.07
                        c0-0.88,0.32-1.57,0.97-2.07c0.64-0.5,1.5-0.75,2.56-0.75c0.88,0,1.61,0.15,2.2,0.44c0.59,0.3,1.09,0.72,1.5,1.26l-1.23,0.98
                        c-0.3-0.36-0.66-0.67-1.07-0.91s-0.87-0.36-1.39-0.36c-0.64,0-1.11,0.12-1.42,0.37s-0.47,0.56-0.47,0.92c0,0.23,0.04,0.43,0.12,0.6
                        s0.23,0.33,0.44,0.48c0.21,0.14,0.49,0.29,0.83,0.43s0.78,0.31,1.31,0.49c1,0.35,1.76,0.79,2.27,1.32C169.74,19,170,19.7,170,20.58
                        c0,0.52-0.1,0.97-0.3,1.37c-0.2,0.4-0.47,0.74-0.81,1.01s-0.75,0.48-1.22,0.62c-0.47,0.14-0.98,0.22-1.55,0.22
                        c-1.08,0-1.92-0.2-2.52-0.59c-0.61-0.39-1.09-0.89-1.45-1.5L163.52,20.88z"
                    />
                </g>
            </svg>
        </div>
    );
}

export default MiracleLogo;
